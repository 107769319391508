import react from "react";
import Plataforma360Presentation from "./presentation/Plataforma360Presentation";
import RobotsLedPresentation from "./presentation/RobotsLedPresentation";
import CabinaPresentation from "./presentation/CabinaPresentation";

const TypeEvent = ({ type, setPack }) => {
  const tipo = type;

  const handle = () => {
    if (tipo === "Plataforma 360°") {
      return <Plataforma360Presentation setPack={setPack} />;
    }

    if (tipo === "Robots LED") {
      return <RobotsLedPresentation />;
    }

    if (tipo === "Cabina fotográfica") {
      return <CabinaPresentation />;
    }

    // if (tipo === "Graduación") {
    //   return (
    //     <div>
    //       <h1>Graduación</h1>
    //     </div>
    //   );
    // }
  };
  return <div>{handle()}</div>;
};
export default TypeEvent;
