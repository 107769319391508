import React, { useEffect, useState } from "react";
import robot from "../../../assets/imgs/presentation/robot.jpeg";

const RobotsLedPresentation = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);
  }, []);
  const styleCard = {
    backgroundColor: "#151729",
    padding: "20px",
    borderRadius: "10px",
    boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
    margin: "20px auto",
    maxWidth: "800px",
    opacity: isVisible ? 1 : 0,
    transform: `translateY(${isVisible ? "0" : "20px"})`,
    transition: "opacity 0.5s ease-in-out, transform 0.5s ease-in-out",
  };

  const titleStyles = {
    fontSize: "2.5rem",
    marginBottom: "20px",
    color: "#ffffff",
  };

  const descriptionStyles = {
    fontSize: "1.2rem",
    color: "#dcdcdf",
    lineHeight: "1.6",
  };

  const cardContainerStyles = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    marginTop: "40px",
  };

  const cardStyles = {
    flex: "1 0 300px", // Esto permite que los elementos se ajusten a la pantalla sin desbordamiento
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    marginBottom: "20px",
    margin: "10px",
  };

  const iconStyles = {
    fontSize: "3rem",
    marginBottom: "20px",
    color: "#5A67D8",
  };

  const imageContainerStyles = {
    flex: "1 0 100%", // Asegura que la imagen se ajuste al ancho del contenedor
    overflow: "hidden",
    borderRadius: "10px",
  };

  const imageStyles = {
    width: "100%",
    height: "auto",
    display: "block",
  };

  return (
    <div style={styleCard}>
      <div>
        <h1 style={titleStyles}>Robots LED</h1>
        <div>
          <p style={descriptionStyles}>
            Personas que utilizan un traje con luces LED de aproximadamente 2.7
            metros.
          </p>
        </div>
      </div>
      <div style={cardContainerStyles}>
        <div style={cardStyles}>
          <i
            className="fas fa-lightbulb text-indigo-500"
            style={iconStyles}
          ></i>
          <p>Luces LED de alta calidad</p>
        </div>
        <div style={cardStyles}>
          <i className="fas fa-users text-indigo-500" style={iconStyles}></i>
          <p>Interacción con personas</p>
        </div>
        <div style={cardStyles}>
          <i className="fas fa-male text-indigo-500" style={iconStyles}></i>
          <p>Diseño humanoide</p>
        </div>
        <div style={cardStyles}>
          <i className="fas fa-magic text-indigo-500" style={iconStyles}></i>
          <p>Efecto visual impactante</p>
        </div>
      </div>
      <div style={imageContainerStyles}>
        <img src={robot} alt="Robots LED" style={imageStyles} />
      </div>
    </div>
  );
};

export default RobotsLedPresentation;
