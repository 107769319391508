import React from "react";
import NavBarLanding from "../../../utils/Navbar/NavBarLanding";

const ServicePackCard = ({
  llave,
  title,
  description,
  price,
  features,
  setPack,
}) => {
  const handleReservar = () => {
    const sectionId = "seccion4";
    const section = document.getElementById(sectionId);
    if (section) {
      window.scrollTo({
        top: section.offsetTop,
        behavior: "smooth",
      });
    }
    setPack(llave);
  };

  return (
    <div>
      <div className="bg-black rounded-lg shadow-lg overflow-hidden relative transform transition-transform duration-500 hover:scale-105 hover:shadow-xl">
        <style>{`
          /* Estilos para el precio */
          .price {
            font-size: 40px; /* Ajusta el tamaño del precio según tus preferencias */
            animation: pulse 2s infinite; /* Crea una animación llamada 'pulse' que dura 2 segundos y se repite infinitamente */
          }

          /* Define la animación 'pulse' */
          @keyframes pulse {
            0% {
              transform: scale(1); /* Escala inicial */
            }
            50% {
              transform: scale(1.2); /* Escala al 120% en el medio de la animación */
            }
            100% {
              transform: scale(1); /* Vuelve a la escala original al final de la animación */
            }
          }

          /* Alineación izquierda para los elementos de la lista */
          ul {
            text-align: left;
          }
        `}</style>
        <div className="p-4">
          <h3 className="text-xl font-semibold text-white">{title}</h3>
          <p className="text-gray-400 mt-2">{description}</p>

          <p className="text-white font-semibold mt-4 price">{price}</p>
          <ul className="mt-4">
            {features.map((feature, index) => (
              <li key={index} className="flex items-center text-gray-300">
                <span className="mr-2">&#8226;</span> {feature}
              </li>
            ))}
          </ul>
        </div>
        <div className="absolute inset-0 bg-gradient-to-r from-blue-400 via-blue-600 to-blue-800 opacity-50"></div>
      </div>
      <button
        disabled={false}
        type="submit"
        onClick={handleReservar} // Utiliza la función pasada como prop
        className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
        style={{
          padding: "10px 20px" /* Ajusta el padding según sea necesario */,
        }}
      >
        Reservar
      </button>
    </div>
  );
};

const Plataforma360Packs = ({ setPack }) => {
  const servicePacks = [
    {
      llave: "Pack único plataforma 360",
      title: "Pack Único",
      description: "Plataforma 360°",
      price: "$180.000",
      features: [
        "Cámara alta resolución",
        "Base plataforma 360° 1 metro de diametro (4 personas máximo)",
        "Alfombra roja",
        "Soporte celular invitados",
        "Diseño video personalizado",
        "Música para video personalizado",
        "Ipad para visualización de video y descarga",
        "Descarga mediante código QR y Airdrop",
        "Personal técnico en el lugar del evento",
        "Funcionamiento con o sin internet",
        "Hora adicional: $50.000",
      ],
    },
  ];

  const numColumns = servicePacks.length > 2 ? 4 : servicePacks.length;

  return (
    // <div className="bg-gradient-to-r from-black to-slate-800 py-12">
    <div>
      <h2
        className="text-3xl font-bold text-center text-white mb-8"
        style={{ marginTop: "20px" }}
      >
        Packs Disponibles
      </h2>

      <div className={`grid grid-cols-1 sm:grid-cols-${numColumns} gap-6`}>
        {servicePacks.map((pack, index) => (
          <ServicePackCard key={index} {...pack} setPack={setPack} />
        ))}
      </div>
    </div>
  );
};

export default Plataforma360Packs;
