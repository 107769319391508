import React from "react";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import { numeroConFormato, instagram, correo } from "../../varGlobal";

const ContactComponent = () => {
  const rrss = [
    {
      id: 1,
      name: "WhatsApp",
      icon: <WhatsAppIcon />,
      color: "text-green-500",
      link: numeroConFormato,
    },
    {
      id: 2,
      name: "Correo",
      icon: <EmailIcon />,
      color: "text-blue-500",
      link: correo,
    },
    {
      id: 3,
      name: "Instagram",
      icon: <InstagramIcon />,
      color: "text-pink-500",
      link: instagram,
    },
  ];
  return (
    <div className="bg-gray-900 p-12 rounded-lg mx-auto my-auto text-center">
      <h2 className="text-4xl font-bold text-white mb-8">Contacto</h2>

      {rrss.map((e) => (
        <div key={e.id} className="text-white mx-auto mb-4">
          <div style={{ display: "inline-block" }}>
            <span className={`text-4xl ${e.color}`}>{e.icon}</span>
          </div>
          <div style={{ display: "inline-block", fontSize: "1.6rem" }}>
            <p className={`text-2xl ${e.color}`}> {e.name}:&nbsp;</p>
          </div>
          <div style={{ display: "inline-block", fontSize: "1.6rem" }}>
            {e.name === "Instagram" ? (
              <a
                href={"https://www.instagram.com/dom.producciones"}
                target="_blank"
                rel="noopener noreferrer"
              >
                {e.link}
              </a>
            ) : (
              e.link
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactComponent;
