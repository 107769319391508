import React from "react";
import DOM from "../../assets/imgs/logo/logoDomProducciones.jpeg";

import "./Heade2.css";
const Header2 = () => {
  return (
    <>
      <h1 className="titulo">DOM Producciones</h1>
      <div className="contenedor">
        <div className="izquierda">
          <img src={DOM} alt="Logo DOM" className="imagen"></img>
          {/* <div class="texto">Agenda Aquí</div> */}
        </div>
        <div className="derecha">
          ¡Experimenta la magia de nuestros servicios! Desde capturar momentos
          inolvidables con nuestra <strong>cabina fotográfica</strong> hasta
          sumergirte en una experiencia envolvente con nuestra{" "}
          <strong>plataforma 360°</strong>. Siente el pulso de la fiesta con
          nuestras <strong>pulseras LED sincronizadas</strong> y deja una huella
          sonora con nuestro <strong>audiolibro para invitados</strong>. Además,
          ¡prepárate para ser sorprendido por la presencia impactante de
          nuestros <strong>Robots LED</strong>! ¡Tu evento merece lo mejor, y
          nosotros lo tenemos todo!
          <div>
            <button className="mt-4 bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded">
              Cotizar servicio
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header2;
