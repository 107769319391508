import React from "react";
import PulserasLedServices from "./presentation/services/pulserasLed.services";

const PulserasLedComponent = () => {
  return (
    <>
      <section style={{ maxWidt: "1000px" }}>
        {" "}
        <PulserasLedServices />
      </section>
    </>
  );
};

export default PulserasLedComponent;
