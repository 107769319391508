import React from "react";
import CardServicio from "../utils/Cards/CardServicio";

// Función require.context para importar dinámicamente imágenes desde una carpeta
const importImages = require.context(
  "../../assets/imgs/servicios/cards",
  false,
  /\.(png|jpe?g|svg)$/
);

const ServiciosComponent = () => {
  // Obtener la lista de nombres de archivo de las imágenes en la carpeta
  const imageFiles = importImages.keys();

  // Crear el array de datos de tarjetas utilizando los nombres de archivo de las imágenes
  const cardData = imageFiles.map((fileName) => ({
    image: importImages(fileName).default,
    nombre: fileName.replace(/^.*[\\/]/, "").split(".")[0], // Obtener el nombre del archivo sin la extensión
  }));

  return (
    <>
      <div className="container px-6 py-10 mx-auto">
        <h1 className="text-3xl font-semibold text-center text-black capitalize lg:text-4xl ">
          Nuestros Servicios
        </h1>

        <div className="grid grid-cols-1 gap-8 mt-8 xl:mt-16 md:grid-cols-2 xl:grid-cols-4">
          {cardData.map((card, index) => (
            <CardServicio key={index} image={card.image} nombre={card.nombre} />
          ))}
        </div>
      </div>
    </>
  );
};

export default ServiciosComponent;
