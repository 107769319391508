import React from "react";

const Footer = ({ name }) => {
  return (
    <footer style={styles.footer}>
      <div style={styles.content}>
        Diseñado y Desarrollado por:{" "}
        <a
          href={"https://cl.linkedin.com/in/diego-ortiz-morales-5ab06811b"}
          target="_blank"
          style={styles.link}
        >
          {name}
        </a>
      </div>
    </footer>
  );
};

const styles = {
  footer: {
    backgroundColor: "#333",
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    // position: "fixed",
    bottom: "0",
    width: "100%",
  },
  content: {
    fontSize: "14px",
    fontWeight: "bold",
  },
};

export default Footer;
