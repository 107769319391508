import React, { useState } from "react";
import "@fortawesome/fontawesome-free/css/all.css";
import { numeroSinFormato } from "../../varGlobal";
const WhatsAppButton = () => {
  const [showText, setShowText] = useState(false);
  const phoneNumber = numeroSinFormato; // Número de teléfono de destino
  const message = "Hola, Me gustaría obtener más información"; // Mensaje predefinido (opcional)

  const handleClick = () => {
    let url = `https://wa.me/${phoneNumber}`;
    if (message) {
      url += `?text=${encodeURIComponent(message)}`;
    }
    window.open(url, "_blank");
  };

  return (
    <div
      style={styles.whatsappButton}
      onClick={handleClick}
      onMouseEnter={() => setShowText(true)}
      onMouseLeave={() => setShowText(false)}
    >
      <div style={styles.contentWrapper}>
        {showText && (
          <span
            style={{
              ...styles.hoverText,
              ...(showText && styles.chatboxAnimation),
            }}
          >
            Cómo te podemos ayudar?
          </span>
        )}
        <i className="fab fa-whatsapp" style={styles.whatsappIcon}></i>
      </div>
    </div>
  );
};

const styles = {
  whatsappButton: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    width: "50px", // Reducir el ancho y alto del botón
    height: "50px", // Reducir el ancho y alto del botón
    backgroundColor: "#25d366",
    borderRadius: "50%", // Mantener el borde redondeado
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    zIndex: "1000",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
  },
  whatsappIcon: {
    fontSize: "32px",
    color: "#fff",
  },
  hoverText: {
    backgroundColor: "#25d366",
    color: "#fff",
    padding: "5px 10px",
    borderRadius: "5px",
    fontSize: "14px",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
    marginRight: "60px",
    opacity: 0,
    transform: "scale(0.9)",
    transition: "opacity 0.3s ease, transform 0.3s ease",
    position: "absolute",
    left: "-250px",
  },
  chatboxAnimation: {
    opacity: 1,
    transform: "scale(1)",
    left: "-140px",
  },
};

export default WhatsAppButton;
