import React, { useState } from "react";

const EventCard = ({ title, description, setEvento }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = (title) => {
    // console.log("Title", title);
    setIsHovered(true);
    setEvento(title); // Aquí estableces el evento como "Boda" cuando el ratón entra en la tarjeta
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    // setEvento(""); // Aquí podrías limpiar el evento si lo deseas al salir del hover
  };
  return (
    <div
      className={`relative w-64 h-48 bg-gradient-to-r from-indigo-300 to-indigo-800 rounded-lg p-6 m-4 transition duration-300 ease-in-out transform hover:scale-105 ${
        isHovered ? "zoom-in" : ""
      } flex flex-col justify-center items-center`}
      onMouseEnter={() => handleMouseEnter(title)}
      onMouseLeave={handleMouseLeave}
      style={{
        transition: "transform 0.3s ease-in-out",
      }}
    >
      <style>
        {`
      .zoom-in:hover {
        transform: scale(1.1) translateY(-5px);
      }
    `}
      </style>

      <h2 className="text-2xl font-bold mb-2 text-center text-white">
        {isHovered ? "" : title}
      </h2>
      {isHovered && (
        <div className="absolute inset-0 bg-black bg-opacity-75 p-6 rounded-lg">
          <p className="text-white">{description}</p>
        </div>
      )}
      <div className="absolute inset-0 flex items-center justify-center">
        <p className="text-white opacity-0 transition-opacity duration-300 ease-in-out group-hover:opacity-100">
          Hover para ver más
        </p>
      </div>
    </div>
  );
};

const EventCardsContainer = ({ setEvento }) => {
  const events = [
    {
      title: "Plataforma 360°",
      description:
        "Experiencia interactiva con videos en 360 grados de alta calidad para rememorar momentos especiales.",
    },
    {
      title: "Robots LED",
      description:
        "Animación luminosa que añade alegría y emoción a eventos y fiestas con estilo.",
    },
    {
      title: "AudioLibro para Invitados",
      description:
        "Graba mensajes personalizados con nuestro audiolibro interactivo para invitados especiales.",
    },
    {
      title: "Pulseras LED Sincronizadas",
      description:
        "¡Pulseras LED: Sincronización musical y elección de colores personalizados!",
    },
    {
      title: "Cabina fotográfica",
      description:
        "Un espacio interactivo que inmortaliza momentos especiales, brindando a los celebrantes recuerdos duraderos y significativos.",
    },
  ];

  return (
    <div>
      <h2 className="text-3xl font-bold text-center text-white mb-8">
        Servicios disponibles
      </h2>
      <div className="flex flex-wrap justify-center">
        {events.map((event, index) => (
          <EventCard
            key={index}
            title={event.title}
            description={event.description}
            setEvento={setEvento}
          />
        ))}
      </div>
    </div>
  );
};

export default EventCardsContainer;
