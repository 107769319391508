import React from "react";
import DOM from "../../assets/imgs/logo/dom.png";
const AboutUs = () => {
  return (
    <div className="bg-gray-200 py-12">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div>
            <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
              Nosotros
            </h2>
            {/* <p className="text-gray-700 mb-4">
              Somos Dom Producciones, una empresa dedicada a brindar servicios
              de calidad en el campo de la producción audiovisual. Nuestro
              objetivo es proporcionar a nuestros clientes las mejores
              soluciones creativas para sus necesidades de contenido visual.
            </p>
            <p className="text-gray-700">
              Con un equipo experimentado y apasionado por el arte audiovisual,
              nos esforzamos por superar las expectativas de nuestros clientes
              en cada proyecto.
            </p> */}
            <p className="text-gray-700 mb-4">
              DOM Producciones se especializa en servicios de entretenimiento
              visual para diversas celebraciones, desde matrimonios hasta
              fiestas corporativas. Ofrecemos cabinas fotográficas, robots LED,
              marcos selfie LED y limbo LED.
            </p>
            <p className="text-gray-700">
              Nuestro enfoque es proporcionar experiencias memorables y de alta
              calidad para nuestros clientes. Con un compromiso inquebrantable
              con la excelencia y la satisfacción del cliente, creamos momentos
              inolvidables en cada evento que atendemos.
            </p>
          </div>
          <div>
            <img src={DOM} alt="Nosotros" className="rounded-lg shadow-md" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
