import React from "react";
import pulsera from "../../../../assets/imgs/servicios/pulserasLed/pulseras2.jpeg";

import "./pulserasLed.services.css"; // Asegúrate de tener la ruta correcta al archivo CSS

const PulserasLedServices = () => {
  return (
    <div
      className="pulserasLed"
      style={{
        backgroundImage: `linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.2),
          rgba(0, 0, 0, 0.9)
        ), url(${pulsera})`,
      }}
    >
      <h1 className="pulserasLed">Pulseras LED Sincronizables</h1>
      <p className="pulserasLed">
        Pulseras LED sincronizables con la música, ideales para fiestas,
        conciertos y eventos. Se pueden sincronizar con cualquier música o
        sonido. La pulsera LED se enciende y se apaga al ritmo de la música.
        Además, se pueden personalizar con el logo de tu empresa o el nombre de
        tu evento.
      </p>
    </div>
  );
};

export default PulserasLedServices;
