import React, { useState } from "react";
import DOM from "../../../assets/imgs/logo/dom.png";

const NavBarLanding = () => {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-1 bg-black mb-3 drop-shadow-md shadow-blue-300">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <img className="h-14 w-16 md:h-20 md:w-24" src={DOM} alt="" />
            <button
              className="text-black  cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-black focus:text-blue-600"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
              </svg>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center" +
              (navbarOpen ? " flex fade-enter fade-enter-active" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
              <li className="nav-item">
                <a
                  href="#seccion1"
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:bg-gradient-to-r from-indigo-300 to-indigo-800 hover:rounded-lg hover:text-white"
                >
                  Inicio
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#seccion2"
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:bg-gradient-to-r from-indigo-300 to-indigo-800 hover:rounded-lg hover:text-white"
                >
                  Nosotros
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#seccion3"
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:bg-gradient-to-r from-indigo-300 to-indigo-800 hover:rounded-lg hover:text-white"
                >
                  Servicios
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#seccion4"
                  className="px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug text-white hover:bg-gradient-to-r from-indigo-300 to-indigo-800 hover:rounded-lg hover:text-white"
                >
                  Contacto
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavBarLanding;
