import { Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import Contacto from "./app/pages/Contacto";
import Home from "./app/pages/Home";
import Nosotros from "./app/pages/Nosotros";
import Servicios from "./app/pages/Servicios";
import NavBarLanding from "./app/components/utils/Navbar/NavBarLanding";
import PulserasLed from "./app/pages/PulserasLed";

function App() {
  return (
    <>
      <style>
        {`
          html {
            scroll-behavior: smooth;
          }
        `}
      </style>
      <NavBarLanding />
      <Outlet />
      <Routes>
        <Route path="" element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/nosotros" element={<Nosotros />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/servicios" element={<Servicios />} />
        <Route path="/pulserasLed" element={<PulserasLed />} />
      </Routes>
    </>
  );
}

export default App;
