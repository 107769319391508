import React, { useState } from "react";
import CustomerExperience from "./CustomerExperience";
import AboutUs from "./AboutUs";
import WhatsAppButton from "./WhatsAppButton";
import Footer from "./Footer";
import ContactComponent from "./ContactComponent";
import ContactForm from "./ContactForm";
import EventCardsContainer from "./EventCardsContainer ";
import TypeEvent from "./TypeEvent";
import Header2 from "./Header2";

const HomeComponent = () => {
  const [evento, setEvento] = useState("");
  const [pack, setPack] = useState("");
  document.body.style.backgroundColor = "#000";
  // console.log("Evento", evento);
  console.log("Pack", pack);
  return (
    <>
      {/* <section id="seccion1">
        <Header />
      </section> */}
      <section id="seccion1">
        <Header2 />
      </section>
      <section id="seccion3">
        <EventCardsContainer setEvento={setEvento} />
      </section>
      <section>
        <TypeEvent type={evento} setPack={setPack} />
      </section>

      {/* <section>
        <InstagramFeed />
      </section> */}
      {/* <section className="w-full mb-28 text-white">
        <ServicePackList />
      </section> */}
      <section>
        <CustomerExperience />
      </section>

      <section id="seccion4">
        <ContactForm pack={pack} />
      </section>
      <section className="w-full mb-28 text-white" id="seccion2">
        <AboutUs />
      </section>
      <section>
        <ContactComponent />
      </section>
      <section>
        <WhatsAppButton />
      </section>
      <section>
        <Footer name={"Diego Ortiz Morales"} />
      </section>
    </>
  );
};

export default HomeComponent;
