import React from "react";

const CustomerExperience = () => {
  // Datos de la experiencia del cliente
  const testimonials = [
    {
      id: 1,
      name: "Juan Pérez",
      comment:
        "¡Excelente servicio! Siempre estoy impresionado por la calidad del trabajo y la atención al cliente.",
    },
    {
      id: 2,
      name: "María Rodríguez",
      comment:
        "¡Increíble experiencia! El equipo de Dom Producciones hizo un trabajo excepcional. ¡Altamente recomendado!",
    },
    // Agrega más testimonios según sea necesario
  ];

  return (
    <div className="bg-gray-100 py-12">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
          Experiencia de Nuestros Clientes
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {testimonials.map((testimonial) => (
            <div
              key={testimonial.id}
              className="bg-white p-6 rounded-lg shadow-md"
            >
              <p className="text-gray-700 mb-4">{testimonial.comment}</p>
              <p className="text-gray-800 font-semibold">{testimonial.name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomerExperience;
