import React, { useState } from "react";
import emailjs from "emailjs-com"; // Importa la biblioteca emailjs-com
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const serviceTypes = ["Matrimonio", "Cumpleaños", "Bautizo", "Fiesta", "Otro"];
const ContactForm = ({ pack }) => {
  const servicePack = pack;
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [service, setService] = useState("");
  const [date, setDate] = useState(new Date());
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [packs, setPacks] = useState(servicePack);
  // const [packs, setPacks] = useState(servicePack);
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validación de campos requeridos
    if (
      !name ||
      !email ||
      !phone ||
      !service ||
      !date ||
      !subject ||
      !message
    ) {
      alert("Por favor, complete todos los campos.");
      return;
    }

    try {
      await emailjs.send(
        "service_k1g1p8i",
        "template_um65x44",
        {
          from_name: name,
          from_email: email,
          phone_number: phone,
          subject: subject,
          message: message,
          date: date.toDateString(),
          service: service,
          pack: packs,
        },
        "IlZC-Rra1_jiRjR-z"
      );

      alert("¡Mensaje enviado con éxito!");
      setName("");
      setEmail("");
      setPhone("");
      setService("");
      setDate(new Date());
      setSubject("");
      setMessage("");
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
      alert(
        "Error al enviar el mensaje. Por favor, inténtelo de nuevo más tarde."
      );
    }
  };

  return (
    <div className="container mx-auto px-4 py-8 flex flex-col items-center justify-center">
      <h2 className="text-3xl font-bold text-center text-white mb-8">
        Formulario de Contacto{" "}
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex flex-col">
          <label htmlFor="name" className="text-sm font-medium text-white mb-1">
            Nombre:
          </label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Tu nombre"
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="email"
            className="text-sm font-medium text-white mb-1"
          >
            Correo Electrónico:
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Tu correo electrónico"
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="phone"
            className="text-sm font-medium text-white mb-1"
          >
            Teléfono:
          </label>
          <input
            type="tel"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Tu número de teléfono"
          />
        </div>
        <div className="flex flex-col">
          <label htmlFor="pack" className="text-sm font-medium text-white mb-1">
            Pack Servicio:
          </label>
          <input
            disabled={true}
            type="text"
            id="pack"
            value={servicePack}
            // required
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="pack"
            style={{
              backgroundColor: "#f0f0f0",
              color: "#999999",
            }}
          />
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="service"
            className="text-sm font-medium text-white mb-1"
          >
            Tipo de Servicio:
          </label>
          <select
            id="service"
            value={service || pack}
            onChange={(e) => setService(e.target.value)}
            required
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          >
            <option value="">Seleccionar tipo de servicio</option>
            {serviceTypes.map((service, index) => (
              <option key={index} value={service}>
                {service}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <label htmlFor="date" className="text-sm font-medium text-white mb-1">
            Fecha:
          </label>
          <DatePicker
            selected={date}
            onChange={(date) => setDate(date)}
            dateFormat="dd/MM/yyyy"
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="subject"
            className="text-sm font-medium text-white mb-1"
          >
            Asunto:
          </label>
          <input
            type="text"
            id="subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            placeholder="Asunto del correo"
          />
        </div>
        <div className="flex flex-col">
          <label
            htmlFor="message"
            className="text-sm font-medium text-white mb-1"
          >
            Mensaje:
          </label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            rows="4"
            placeholder="Tu mensaje"
          />
        </div>
        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded"
        >
          Enviar
        </button>
      </form>
    </div>
  );
};

export default ContactForm;
